:root {
  --size-300: 0.75rem;
  --size-400: 1rem; /* body text */
  --size-450: 1.25rem;
  --size-500: 1.5rem;
  --size-600: 2.2rem;
  --width: 1280px;
  --gutter: var(--size-600);
  --flyer-white: #fff;
  --flyer-black: #000;
  --flyer-grey: #464646;
  --flyer-pink: #FF8F8F;
  --flyer-pink-light: #FFDABF;
}

@font-face {
  font-family: "outfit";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF; /* Downloads only latin glyphs */
  src: url("../fonts/outfit-regular.woff2") format("woff2"), url("../fonts/outfit-regular.woff") format("woff");
}
@font-face {
  font-family: "outfit";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF; /* Downloads only latin glyphs */
  src: url("../fonts/outfit-medium.woff2") format("woff2"), url("../fonts/outfit-medium.woff") format("woff");
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html, body, main, div, span, h1, h2, p, a, img, small, strong, ul, li, article, footer, header, section, picture {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html, body {
  background-color: #FFF;
  color: #000000;
}

body {
  line-height: 1.5;
  font-family: "outfit", sans-serif;
  font-weight: 400;
  font-style: 16px;
}

strong {
  font-weight: 500;
}

h1, .h1 {
  font-size: var(--size-600);
  font-weight: 500;
  line-height: 1.3;
}

h2 {
  font-size: var(--size-500);
  font-weight: 500;
  line-height: 1.4;
}

h3 {
  font-size: var(--size-450);
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

p, li {
  font-size: var(--size-400);
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.inner-wrapper {
  width: 100%;
  max-width: var(--width);
  margin: 0 auto;
}

.hero {
  width: 100%;
}
.hero .inner-wrapper {
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hero .inner-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .hero .inner-wrapper .hero--text,
  .hero .inner-wrapper .hero--iphone {
    width: 50%;
  }
}
.hero .hero--text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: calc(var(--gutter) / 2);
  color: var(--flyer-white);
  width: 100%;
  position: relative;
  z-index: 1;
  padding: calc(var(--gutter) * 2) var(--gutter) var(--gutter);
}
@media (min-width: 1024px) {
  .hero .hero--text {
    position: absolute;
    height: 100%;
    gap: var(--gutter);
    padding: calc(var(--gutter) * 2);
  }
}
.hero .hero--text > * {
  -webkit-filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
}
.hero .hero--logo {
  width: 148px;
}
.hero .hero--buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: var(--size-300);
}
.hero .hero--buttons img {
  width: auto;
  max-height: 50px;
}
.hero .hero--background {
  width: 100%;
  height: initial;
}
@media (max-width: 1023px) {
  .hero .hero--background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .hero .hero--background:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
  }
}
.hero .hero--background img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 0 0 var(--size-300) var(--size-300);
}
@media (max-width: 1023px) {
  .hero .hero--background img {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.hero .hero--iphone {
  width: initial;
  height: initial;
  position: relative;
  z-index: 1;
}
@media (min-width: 1024px) {
  .hero .hero--iphone {
    position: absolute;
    bottom: 0;
    right: var(--gutter);
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
.hero .hero--iphone img {
  width: 100%;
  height: auto;
  max-width: 350px;
}
@media (min-width: 1024px) {
  .hero .hero--iphone img {
    max-width: 300px;
  }
}

.app-info .inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: var(--gutter);
  padding: var(--gutter);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .app-info .inner-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media (min-width: 1024px) {
  .app-info .inner-wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    gap: var(--gutter);
    padding: calc(var(--gutter) * 2) var(--gutter);
  }
}
.app-info .inner-wrapper > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: calc(var(--gutter) / 2);
  text-align: center;
  width: 100%;
  color: var(--flyer-pink);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .app-info .inner-wrapper > div {
    width: calc(50% - var(--gutter) / 2);
  }
  .app-info .inner-wrapper > div:last-of-type {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .app-info .inner-wrapper > div {
    width: 21%;
  }
}
@media (min-width: 1200px) {
  .app-info .inner-wrapper > div {
    width: 22%;
  }
}
.app-info .inner-wrapper > div svg {
  width: auto;
  height: 80px;
}
.app-info .inner-wrapper > div h2 {
  max-width: 20ch;
}

.app-cta {
  padding: 0 var(--gutter);
  margin: var(--gutter) 0 calc(var(--gutter) * 2) 0;
  color: var(--flyer-grey);
}
@media (min-width: 1024px) {
  .app-cta {
    margin: 0 0 calc(var(--gutter) * 2) 0;
  }
}
@media (min-width: 1300px) {
  .app-cta {
    padding: 0;
  }
}
.app-cta .inner-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: var(--size-400);
  padding: var(--gutter) var(--gutter) 0;
  background-color: var(--flyer-pink-light);
  border-radius: var(--size-300);
  position: relative;
  overflow: hidden;
  background-image: url("../media/flyer-logo-repeater.png");
  background-repeat: repeat;
}
@media (min-width: 1024px) {
  .app-cta .inner-wrapper {
    padding: var(--gutter) calc(var(--gutter) * 2);
  }
}
.app-cta .inner-wrapper > * {
  position: relative;
  z-index: 2;
}
.app-cta .inner-wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(75deg, rgb(255, 218, 191) 0%, rgb(255, 218, 191) 47%, rgba(255, 218, 191, 0.3) 100%);
  z-index: 1;
}
@media (min-width: 1024px) {
  .app-cta .inner-wrapper:after {
    background: linear-gradient(31deg, rgb(255, 218, 191) 0%, rgb(255, 218, 191) 47%, rgba(255, 218, 191, 0.3) 100%);
  }
}
.app-cta .app-cta--buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: var(--size-300);
  margin-top: var(--gutter);
}
.app-cta .app-cta--buttons img {
  width: auto;
  max-height: 50px;
}
.app-cta .app-cta--image {
  width: 100%;
  height: auto;
}
.app-cta .app-cta--image img {
  display: block;
  -webkit-transform: translateX(-25%);
          transform: translateX(-25%);
}
@media (min-width: 768px) {
  .app-cta .app-cta--image img {
    -webkit-transform: translateX(-10%);
            transform: translateX(-10%);
  }
}
@media (min-width: 1024px) {
  .app-cta .app-cta--image img {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
}
@media (min-width: 1200px) {
  .app-cta .app-cta--image img {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}
@media (min-width: 1024px) {
  .app-cta .app-cta--image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: auto;
    height: 100%;
    z-index: 2;
  }
}
@media (min-width: 1200px) {
  .app-cta .app-cta--image {
    height: 120%;
  }
}